<template>
  <div>
    <b-dropdown variant="link" no-caret size="sm" toggle-class="text-decoration-none p-0" right>
      <template #button-content>
        <div class="items-web-color">
          <b-spinner class="items-web-color" small v-if="isReloadingExperiences || isLoadingInitialData" />
          <span v-if="currency">
            {{currency.code}} <feather-icon icon="ChevronDownIcon" />
          </span>
        </div>
      </template>
      <b-dropdown-item v-for="cur in currencies" :key="cur.id" :value="cur.currencyid" @click="handleGlobalCurrency(cur)">
        <span class="currency-drop">
          {{cur.name}} ({{ cur.code }})
        </span>
      </b-dropdown-item>
    </b-dropdown>
    <label v-if="currency && currency.code != 'USD'">
      {{formatCurrency(currency.value)}}
    </label>
  </div>
</template>

<script>
import { currentDate, stringAleatorio, toCurrency, toJson } from '@/helpers/helpers'
import {  mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [ market ],
  async mounted(){
    this.isLoadingInitialData = true
    if( !this.currencies.length ){
      const currencies = await this.fetchCurrencies()
      this.setCurrencies(currencies) //muto state currencies en start
    }
    if( !this.currency ){
      this.setGlobalCurrency(this.currencies[0])
      this.setCurrency(this.currencies[0])
    }
    if (!this.temporalCurrency) {
      this.temporalCurrency = this.currency
    }
    this.isLoadingInitialData = false
  },
  data(){
    return {
      isLoadingInitialData: false,
      isReloadingExperiences: false,
      temporalCurrency: null
    }
  },
  computed:{
    ...mapState("shop", ["cart", "currency" ,"paymentFormat"]),
    ...mapState("start", ["currencies", "payloadParamsRates"]),
    ...mapState("products", ["selectedCategory"]),
    ...mapGetters("shop", ["totalBalance"]),

    temporalValue(){
      // const currentValue = this.currency.value

      return toCurrency((this.temporalCurrency.value / this.currency.value), this.temporalCurrency.code, this.temporalCurrency.locale)
    }
  },
  methods: {
    ...mapActions('shop',['calculateTotalWithDiscounts']),
    ...mapMutations('shop',['setGlobalCurrency', 'setCurrency', 'clearPaymentList']),
    ...mapMutations('products',['setProducts', 'resetQtyProductDetail']),
    ...mapActions('start',['fetchCurrencies']),
    ...mapActions("products", ["fetchProductsRate" ,"fetchPackages", "fetchEvents", "fetchProductDetail","fetchExperienceDetail", "fetchTransfersRate"]),
    ...mapMutations('start',['setCurrencies']),

    async handleGlobalCurrency(currency){
      this.isReloadingExperiences = true
      this.setGlobalCurrency(currency)
      let products
      if (this.cart.items.length>0) await this.calculateTotalWithDiscounts()
      this.setCurrency(currency)
      this.paymentFormat.total = this.totalBalance;

      if( this.selectedCategory.action == 'get-packages' ) products = await this.fetchPackages()
      else if( this.selectedCategory.action == 'get-events' ) products = await this.fetchEvents()
      else {
        const payload = !!this.payloadParamsRates ? this.payloadParamsRates : {
          adults: 1,
          children: 0,
          dateRange: currentDate(),
          idCat: null,
          idsub: null
        }

        products = await this.fetchProductsRate(payload)
      }
      this.setProducts(products)
      this.isReloadingExperiences = false
    },
    formatCurrency(value){
      return toCurrency(value, this.currency.code, this.currency.locale)
    }
  }
}
</script>

